import dynamic from "next/dynamic";
import { useRouter } from 'next/router';
import CustomPage from "@/components/CustomPage";

const DynamicLoginComponent = dynamic(() =>
  import("../../components/LoginComponent").then((mod) => mod.LoginComponent)
);

export default function Login() {
  // Obtém o objeto de roteamento
  const router = useRouter();
  const { register } = router.query;

  return (
    <CustomPage
      canonicalUrl={`https://elojobrise.com/login`}
      title="ELOJOB RISE - LOGIN"
    >
      <DynamicLoginComponent register={register === "true" ? true:false}/>
    </CustomPage>
  );
}
